import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';

export default class AboutUs extends Component {
  componentDidMount() {
    if(window.innerWidth > 768) {
    if(this.props.location.hash === '#team') {
      window.scrollTo(0, 1150);
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      if(this.props.location.hash === '#team') {
        window.scrollTo(0, 1300);
        } else {
          window.scrollTo(0, 0);
        }
    }
  }

  render() {
    return (
      <Grid container>
        <Grid item className="about-us">
          <img src={require('../../assets/img/illustration2.svg')} alt="cubes" className="cubes" />
          <div className="first-section-wrapper">
            <h1 className="section-title">About us</h1>
            <p>Just over a year ago we came to an idea to change the world. "Record scratch sound"</p>
            <p> Wait, what? How many times have you heard that one? One too many? Well, it’s true.
              It’s kind of overused.</p>
            <p> We don’t want to change the world. We want to ignite the world
            to change itself, and for the better. Watching the movie unfold in front of our eyes
            is not good enough anymore. It’s about taking action, it’s about taking active role
            in doing the job that needs to be done. It’s about pushing that small piece of domino
            that will fall onto a bigger one. And another, even bigger. That domino effect is what
              we want to create. Ignite to change.</p>
            <p> By creating innovative solutions in transportation
            industry as our primary goal – but with an extra sauce. “What is that sauce”, you might ask?
            It’s called “personalized experience”. And we are here to build that system from scratch.
            So watch us move that first domino piece. Help us move more, and bigger pieces.</p>
            <p>Be one of us. Ignite it.</p>
          </div>
        </Grid>
        <Grid item xs={12} className="our-process">
          <div className="title-wrapper">
            <h2 className="section-title"><span>how we work</span>our process</h2>
          </div>
          <div>
            <img src={require('../../assets/img/our-process.svg')} alt="agile example" className="agile-img" />
            <div className="agile-text-wrapper">
              {/* <div><p>Individuals and interactions rather than processes and tools</p></div>
              <div><p>Development of working software</p></div>
              <div><p>Collaboration with customer or the client</p></div>
              <div><p>Quickly responding to change</p></div> */}
            </div>
            <p>Even though we work on our solutions, we love to work with clients who have interesting projects.
              We value our interaction with clients, from initial contact to rollout of the service, our main goal
              is to together make a better product with joined knowledge. When a client comes to us with the project
              idea, we analyze the industry they are coming from, we strive to understand the needs and what will
              this service offer to their business. Doing it like this helps us to bring more to the table, it helps
              us to anticipate the future of product and to build it the way it was meant to be built. This is just
              one of the things that differentiates us from the majority of software development companies.</p>
            <p>
              In-house we use Agile for software development, however our Project Managers are certified in other
              methodologies as well. As an Agile company we follow team norm principles where each person is encouraged
              to share their ideas and be comfortable speaking up, each person also has a responsibility to speak up and
              share their thoughts, and team members need to listen carefully to the ideas being shared. As this could
              spawn other great ideas and potential solutions.
              </p>
          </div>
        </Grid>
        <Grid item xs={12} className="team" id="team">
          <div className="title-wrapper">
            <h2 className="section-title"><span>meet our people</span>our team</h2>
          </div>
          <div className="team-wrapper">
            <div className="team-member">
              <div className="avatar zoran" />
              <p className="name">Zoran Tadic</p>
              <p className="title">CO-FOUNDER / MANAGING DIRECTOR</p>
              <a href="https://www.linkedin.com/in/zoran-tadic-269b94a/" target="_blank" rel="noopener noreferrer" className="social-wrapper">
                <img src={require('../../assets/img/linkedin-logo.svg')} alt="linkedin" />
              </a>
            </div>
            <div className="team-member">
              <div className="avatar mladja" />
              <p className="name">Mladjen Merdovic</p>
              <p className="title">CO-FOUNDER / STRATEGY DIRECTOR</p>
              <a href="https://www.linkedin.com/in/mladjen-merdovic-a861b841/" target="_blank" rel="noopener noreferrer" className="social-wrapper">
                <img src={require('../../assets/img/linkedin-logo.svg')} alt="linkedin" />
              </a>
            </div>
            <div className="team-member">
              <div className="avatar craig" />
              <p className="name">Craig Sellars</p>
              <p className="title">CO-FOUNDER / TECHNOLOGY DIRECTOR</p>
              <a href="https://www.linkedin.com/in/craigcsellars/" target="_blank" rel="noopener noreferrer" className="social-wrapper">
                <img src={require('../../assets/img/linkedin-logo.svg')} alt="linkedin" />
              </a>
            </div>
            <div className="team-member">
              <div className="avatar milan" />
              <p className="name">Milan Lukic</p>
              <p className="title">product owner</p>
              <a href="https://www.linkedin.com/in/milan-lukić-a9bb6477/" target="_blank" rel="noopener noreferrer" className="social-wrapper">
                <img src={require('../../assets/img/linkedin-logo.svg')} alt="linkedin" />
              </a>
            </div>
            <div className="team-member">
              <div className="avatar nikola" />
              <p className="name">Nikola Jovanovic</p>
              <p className="title">product owner</p>
              <a href="http://www.linkedin.com" className="social-wrapper">
                <img src={require('../../assets/img/linkedin-logo.svg')} alt="linkedin" />
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}
