/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';

export default class BlogPage extends Component {
  render() {
    return (
      <Grid container className="blog-page">
        <Grid item xs={12}>
          <h1 className="section-title"><span>latest news</span>our blog</h1>
          <div className="inner">
            {/*<div className="blog-post">
              <img src={require('../../assets/img/best-ssds-hub.png')} alt="post" className="post-image"/>
              <div className="post-info">
                <p className="category">pc news
                  <hr />
                </p>
                <p className="date">04/12/2019</p>
              </div>
              <h3 className="post-title">Best SSD’s for 2020: In-depth reviews of our experts</h3>
              <div className="link-wrapper">
                <a href="#">Read Article ></a>
              </div>
            </div>
            <div className="blog-post">
              <img src={require('../../assets/img/best-ssds-hub.png')} alt="post" className="post-image"/>
              <div className="post-info">
                <p className="category">pc news
                  <hr />
                </p>
                <p className="date">04/12/2019</p>
              </div>
              <h3 className="post-title">Best SSD’s for 2020: In-depth reviews of our experts</h3>
              <div className="link-wrapper">
                <a href="#">Read Article ></a>
              </div>
            </div>
            <div className="blog-post">
              <img src={require('../../assets/img/best-ssds-hub.png')} alt="post" className="post-image"/>
              <div className="post-info">
                <p className="category">pc news
                  <hr />
                </p>
                <p className="date">04/12/2019</p>
              </div>
              <h3 className="post-title">Best SSD’s for 2020: In-depth reviews of our experts</h3>
              <div className="link-wrapper">
                <a href="#">Read Article ></a>
              </div>
            </div>*/}
          </div>
        </Grid>
      </Grid>
    )
  }
}
