import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';

export default class CareersPage extends Component {
  state = {
    show: false
  };
  state = {
    hide: true
  };

  showhide = () => {
    this.setState({ show: !this.state.show });
    this.setState({ hide: !this.state.hide });
  };
  render() {
    return (
      <Grid container className="careers-page">
        <Grid item xs={12}>
        <h1 className="section-title"><span>work with us</span>JOB OPENINGS</h1>
        <div className="inner">
          {/*<img src={require('../../assets/img/careers-image1.png')} alt="office" className="office-img" />
          <div className="careers-image-wrapper">
          <img src={require('../../assets/img/careers-image2.png')} alt="office"  />
          <img src={require('../../assets/img/careers-image3.png')} alt="office"  />
          </div>*/}
          <div className="section-head"> 
            <div onClick={this.showhide} className="job-title">  
              <h3>React Front-End Developer</h3>
              {this.state.hide && <h3 className="see-more">View position</h3> }
              {this.state.show && <h3 className="see-more">Close</h3> }
            </div>
            {this.state.show &&  <div className="section-body">
              <div className="team-illustration">
                <img src={require('../../assets/img/react-dev.svg')} alt="dev-team" />
              </div>
              <div className="job-description">
                <p>We are looking for a talented React developer with knowledge of jQuery, Firebase and Glamourous. Someone who is hungry for knowledge and can take directions from our Lead developer. We will need this person to work 20-30 hours per week with a prospect of turning this role into a full-time employment. Since we work with our customers overseas, it is important that this person is flexible with their work schedule.</p><p>We are located in the Usce business building with a beautiful view of the city.</p>
                <ul>
                  <h3>We are looking for:</h3>
                  <li><p>3+ years of professional experience with Frontend area</p></li>
                  <li><p>React</p></li>
                  <li><p>jQuery</p></li>
                  <li><p>Firebase</p></li>
                  <li><p>Glamorous</p></li>
                  <li><p>Good command of spoken and written English</p></li>
                </ul>
                <br />
                <p>If you believe you have what it takes to be one of us - send your CV to <a href="mailto:hr@its42.io" style={{ color: '#A32036' }}>hr@its42.io</a> and we promise to get back to you ASAP.</p>
              </div>
            </div>} 
          </div>
        </div>
        </Grid>
      </Grid>
    )
  }
}
