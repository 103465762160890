import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import { Element } from 'react-scroll'
import AppButton from '../../elements/form/AppButton';
import { Link } from 'react-router-dom';

export default class Projects extends Component {
  render() {
    return (
      <Grid item xs={12} className="projects">
        <Element name="projects" className="element" >
          <h2 className="section-title">showcase<span>powered by blockchain and AI</span></h2>
          <div className="inner">
            <div>
              <h3 className="project-name ivoy">ivoy<span>PRIVATE JET BOOKING APP</span></h3>
              <p>Book your next charter flight in just a few minutes.
                <br />
                Everything about Ivoy is designed around one specific goal:
                to make charter flights more accessible.
                <br />
                We establish direct connections
                between clients and service providers, which not only makes booking a
                private jet an effortless experience, but also helps significantly lower
                the price of charter flights by removing pricey brokers or other unnecessary
                middlemen from the equation.
                <br />
                With hundreds of service providers competing to
                offer you the best price for your next trip, all the power is in your hands.
              </p>
              <br />
              <Link to="/projects"><AppButton title="Learn more" fullWidth={false} className="btn red" /></Link>
            </div>
            <img src={require('../../assets/img/ivoy-project-mockup.png')} alt="ivoy platform" />
            {/* <div className="arrow-right" /> */}
          </div>
          <style>
            @import url('https://fonts.googleapis.com/css?family=Comfortaa:700&display=swap');
          </style> 
        </Element>
      </Grid>
    )
  }
}
