import React, { Component } from 'react'

export default class Stripe extends Component {
  state = {
    stripeWidth: 690
  }
   componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location) {
      this.getStripeWidth();
    }
  }
  componentDidMount() {
    this.getStripeWidth()
  }

  getStripeWidth() {
    let stripe = document.getElementById('stripe')
   if(this.props.location.pathname === '/about') {
    stripe.className = "stripe about";
    } else if (this.props.location.pathname === '/projects') {
        stripe.className = "stripe projects-length";
    }  else if (this.props.location.pathname === '/') {
        stripe.className = "stripe home";
    } else if (this.props.location.pathname === '/careers') {
        stripe.className = "stripe careers";
    } else if (this.props.location.pathname === '/blog') {
        stripe.className = "stripe blog";
    } else if (this.props.location.pathname === '/contact') {
        stripe.className = "stripe contact-length";
    }
  }
  render() {
    return (
      <div id="stripe" className="stripe home"  />
    )
  }
}
