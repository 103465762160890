import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Input from '@material-ui/core/Input';


const styles = theme => ({
  root: {
    height: 40,
    padding: 10,
    background: 'white',
    borderRadius: 4,
  },
  input: {
    '&::placeholder': {
      color: '#7D7D7D',
      opacity: 1
    },
    width: '100%',
    display: 'flex',
    padding: 10,
    color: '#7D7D7D',
    fontSize: 14,
    fontWeight: 400,
    // backgroundColor: 'white',
    // borderBottom: '1px solid white',
    // height: 40,
    fontFamily: 'Montserrat-Regular',
  },
});

class AppTextInput extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <Input
        id={this.props.id}
        className={classes.input}
        disableUnderline={true}
        value={this.props.value}
        onChange={this.props.onChange}
        // defaultValue=""
        // margin="normal"
        // fullWidth={true}
        {...this.props}
      />
      // <TextField
      //   id=''
      //   className={classes.input}
      //   defaultValue=""
      //   margin="normal"
      //   fullWidth={true}
      //   {...this.props}
      // />
    );
  }
}

AppTextInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppTextInput);