import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';

export default class Projects extends Component {
  componentDidMount() {
    window.scrollTo(0,0)
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12} className="projects-page">
          <h1 className="section-title">our showcase</h1>
          <div className="project-card">
            <div className="wrapper">
              <div className="text">
                <h2 className="section-title" style={{ textTransform: 'lowercase', fontSize: 40 }}><span>private jet booking app</span>ivoy</h2>
                <p>Book your next charter flight in just a few minutes. <br />
                  Everything about Ivoy is designed around one specific goal: to make charter flights more accessible. <br />

                  We establish direct connections between clients and service providers, which not only makes booking a
                  private jet an effortless experience, but also helps significantly lower the price of charter flights
                  by removing pricey brokers or other unnecessary middlemen from the equation. With hundreds of service
                  providers competing to offer you the best price for your next trip, all the power is in your hands.
            </p>

                <p>Through the use of AI and blockchain technology, we are planning to offer a platform that will think
                  about what you need before you need it.</p>
                <p> Offering additional services based on your preferences and
                bringing you peace of mind wherever you go. Next phase for this product is to implement ride solution
                (taxi) and package transportation service to accommodate this circle of logistical platforms. These
                services can also be used standalone, however the strength comes from their joined backbone. Through
                time, Ivoy will offer you places to stay when you are traveling, order your favorite food in advance
                 wherever you go, or plan your whole vacation based on your interests.</p>
              </div>
              <img src={require('../../assets/img/ivoy1.png')} alt="ivoy app" className="ivoy-app-image" />
            </div>
          </div>
          <div className="project-card">
            <div className="wrapper">
              <img src={require('../../assets/img/logistics.png')} alt="logistics app" className="logistics-image" />
              <div className="text right">
                <h2 className="section-title" style={{ textAlign: 'right' }}><span>web & mobile app</span>logistics</h2>
                <p >
                  Transport management platform for logistics, fleet management, and sales in transportation industry.
                  Using this software, shipper/carrier sales teams as well as dispatch teams, will manage transport
                  requests with high efficiency.</p> <p >It is supported by AI offered routes and solutions, and the reporting
                  tool for statistics data analysis.</p>
              </div>
            </div>
          </div>
          <div className="project-card">
            <div className="wrapper">
              <div className="text">
                <h2 className="section-title" ><span>mobile app</span>hospitality</h2>
                <p>Mobile application for hospitality industry, mainly focused for restaurants, where users will have
                  rating and tipping functionality with crypto.</p> <p>This app will also enable businesses to see the customer
                  habits, and to bring the service to the higher level.
            </p>
              </div>
              <img src={require('../../assets/img/hospitality.png')} alt="hospitality app" className="ivoy-app-image" />
            </div>
          </div>
           <div className="project-card">
            <div className="wrapper">
              <img src={require('../../assets/img/cargo.png')} alt="logistics app" className="logistics-image" />
              <div className="text right">
                <h2 className="section-title" style={{ textAlign: 'right' }}><span>mobile app</span>cargo</h2>
                <p >
                  Mobile application for fast booking of transportation services based on bidding concept.
                  App is divided with interface for people who need transportation service and the companies
                  that offer those services.</p>
                  <p > The goal was to satisfy the huge variety of needs for small, one
                  time shipments, as well as regular scheduled shipments of larger scale. Bidding concept brings
                  the healthy competition as well as lower prices. Based on point of view of companies that offer
                  transportation services, app is suitable and has a potential to kickstart small family businesses
                  and to bring more stable income rate for bigger companies by opening the door of the whole new marketplace.
                </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}
