import React from 'react';
import './styles/style.scss';
import './App.css';
import { withRouter, Route, Switch, Link } from "react-router-dom";
import Footer from './components/footer/Footer';
import Home from './modules/home/Home';
import AboutUs from './modules/about/AboutUs';
import Projects from './modules/projects/Projects';
import ContactPage from './modules/contact/ContactPage';
import SuccessPage from './modules/contact/SuccessPage';
import CareersPage from './modules/careers/CareersPage';
import BlogPage from './modules/blog/BlogPage';
import AppButton from './elements/form/AppButton';
import Stripe from './components/navigation-stripe/Stripe';
import DrawerHome from './components/navigation/DrawerHome';
import MediaQuery from 'react-responsive';
import { loadReCaptcha } from 'react-recaptcha-google';
import NavLogo from './components/navigation-logo/NavLogo';


// function App() {
class App extends React.Component {
  componentDidMount() {
    loadReCaptcha();
  }



  render() {
    return (
      <div className="App">
        <MediaQuery query='(min-width: 991px)'>
          <nav className="top-navigation">
            <ul>
              <li>
                <Link to="/" >
                  Home
              </Link>
              </li>
              <li>
                <Link to="/about" >
                  About
              </Link>
              </li>
              <li>
                <Link to="/projects" >
                  Projects
              </Link>
              </li>
              <li>
                <Link to="/careers" >
                  Careers
              </Link>
              </li>
              {/*<li>
                <Link to="/blog">
                  Blog
              </Link>
              </li>*/}
              <li>
                <Link to="/contact" >
                  <AppButton title="Contact" className="btn red" style={{ padding: '3px 20px' }} />
                </Link>
              </li>
            </ul>
            <Stripe {...this.props} />
            <div className="stripe-horisontal" />
            <NavLogo {...this.props} />
          </nav>
        </MediaQuery>
        <MediaQuery query='(max-width: 991px)'>
          <DrawerHome />
        </MediaQuery>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/careers" component={CareersPage} />
          <Route exact path="/blog" component={BlogPage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/success" component={SuccessPage} />
        </Switch>
        <Footer />
      </div>
    );
  }
}


export default withRouter(App);
