import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../elements/form/AppTextInput';
import AppButton from '../../elements/form/AppButton';
import { ReCaptcha } from 'react-recaptcha-google';
import axios from 'axios';

export default class ContactPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      from: '',
      subject: '',
      name: '',
      text: '',
    }
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.captchaDemo) {
      console.log("started, just a second...")
      this.captchaDemo.reset();
      // this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      // this.captchaDemo.execute();
    }
  }
  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    console.log(recaptchaToken, "<= your recaptcha token")
  }

  async sendEmail() {
    let res = await axios({
      method: 'post',
      url: 'https://api.eu.mailgun.net/v3/mg.its42.io/messages',
      auth: {
        username: 'api',
        password: 'fe12c125cdb3bc636c6928d4107c2b90-0a4b0c40-d4da2a5b'
      },
      params: {
        from: `${this.state.name} ${this.state.from}`,
        to: 'office@its42.io',
        subject: 'Its42 Contact Form',
        text: this.state.text
      }
    }).then(
      response => {
        console.log(response)
        this.setState({
          from: '',
          subject: '',
          name: '',
          text: '',
        })
      },
      reject => {
        console.log(reject)
      }
    )
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.history.push('/success');
    // const { data } = this.state
    // mailgun.messages().send(data, (error, body) => {
    //   console.log(body);
    // });
    this.sendEmail()
  }




  render() {
    return (
      <Grid container className="contact-page">
        <Grid item xs={12}>
          <h1 className="section-title"><span>get in touch</span>Contact us</h1>
          <div className="inner">
            <div className="item">
              {/*<p>Drop us a line on <a href="mailto:office@its42.io" style={{ color: '#A32036' }}>office@its42.io</a> and we will get back to you soon.</p>*/}
              <img src={require('../../assets/img/contact-map.svg')} alt="map" className="map-image" />
            </div>
            <div className="item">
              <div className="contact-wrapper">
                <div className="contact-info">
                  <div>
                    <p className="label">Our Location</p>
                    <p className="info"><img src={require('../../assets/img/facebook-placeholder.svg')} alt="map placeholder" /> Jurija Gagarina 14B/45, Belville<br />Belgrade, Serbia</p>
                    {/* <p className="info"><img src={require('../../assets/img/email-icon.svg')} alt="email" /> office@its42.io</p> */}
                  </div>
                  <br />
                  <br />
                  {/* <div> */}
                  {/* <p className="label">Connect with us</p> */}
                  {/* <br /> */}
                  {/*<a href="http://www.facebook.com" target="_blank" rel="noopener noreferrer"><img src={require('../../assets/img/facebook-icon.svg')} className="social-icon" alt="facebook icon" /></a>*/}
                  {/* <a href="https://www.linkedin.com/company/its42" target="_blank" rel="noopener noreferrer">  <img src={require('../../assets/img/linkedin-icon.svg')} className="social-icon" alt="facebook icon" /></a> */}
                  {/*<a href="http://www.facebook.com" target="_blank" rel="noopener noreferrer"><img src={require('../../assets/img/twitter-icon.svg')} className="social-icon" alt="twitter icon" /></a>*/}
                  {/* </div> */}
                </div>
                {/*<img src={require('../../assets/img/usce-building.png')} alt="usce" className="usce-img" />*/}

              </div>
            </div>
            <div className="item">
              <form className="contact-form">
                <div className="input-wrapper">
                  <label htmlFor="name">Your name</label>
                  <AppTextInput
                    id="name"
                    placeholder="eg. Matt Bale"
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="email">Your email</label>
                  <AppTextInput
                    id="email"
                    placeholder="eg. matt@gmail.com"
                    onChange={(e) => this.setState({ from: e.target.value })}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="message">Your message</label>
                  <AppTextInput
                    id="message"
                    placeholder="Type your message here"
                    multiline={true}
                    style={{ minHeight: 200, display: 'flex', alignItems: 'flex-start' }}
                    onChange={(e) => this.setState({ text: e.target.value })}
                  />
                </div>
                <ReCaptcha
                  ref={(el) => { this.captchaDemo = el; }}
                  size="normal"
                  render="explicit"
                  sitekey="6LemI84UAAAAAMRPBxev78gN6BgkszXl616K60ED"
                  onloadCallback={this.onLoadRecaptcha}
                  verifyCallback={this.verifyCallback}
                />
                <AppButton title="Send message" type="submit" onClick={this.onSubmit} className="btn red" fullWidth={true} />
              </form>
            </div>
          </div>
        </Grid>
        {/* <img src={require('../../assets/img/office-view.png')} alt="office view" /> */}
        <div className="bg-image" />
      </Grid>
    )
  }
}
