import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';

export default class ContactPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.captchaDemo) {
      console.log("started, just a second...")
      this.captchaDemo.reset();
      // this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      // this.captchaDemo.execute();
    }
  }
  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!  
    console.log(recaptchaToken, "<= your recaptcha token")
  }

  render() {
    return (
      <Grid container className="contact-page">
        <Grid item xs={12}>
          <h1 className="section-title"><span>get in touch</span>Contact us</h1>
          <div className="inner">
            <div className="item">
              {/*<p>Drop us a line on <a href="mailto:office@its42.io" style={{ color: '#A32036' }}>office@its42.io</a> and we will get back to you soon.</p>*/}
              <img src={require('../../assets/img/contact-map.svg')} alt="map" className="map-image" />
            </div>
            <div className="item">
              <div className="contact-wrapper">
                <div className="contact-info">
                  <div>
                    <p className="label">Our Location</p>
                    <p className="info"><img src={require('../../assets/img/facebook-placeholder.svg')} alt="map placeholder" /> Mihajla Pupina 6, 19th floor<br />Belgrade, Serbia</p>
                    {/* <p className="info"><img src={require('../../assets/img/email-icon.svg')} alt="email" /> office@its42.io</p> */}
                  </div>
                  <br />
                  <br />
                  {/* <div> */}
                    {/* <p className="label">Connect with us</p> */}
                    {/* <br /> */}
                    {/*<a href="http://www.facebook.com" target="_blank" rel="noopener noreferrer"><img src={require('../../assets/img/facebook-icon.svg')} className="social-icon" alt="facebook icon" /></a>*/}
                    {/* <a href="https://www.linkedin.com/company/its42" target="_blank" rel="noopener noreferrer">  <img src={require('../../assets/img/linkedin-icon.svg')} className="social-icon" alt="facebook icon" /></a> */}
                    {/*<a href="http://www.facebook.com" target="_blank" rel="noopener noreferrer"><img src={require('../../assets/img/twitter-icon.svg')} className="social-icon" alt="twitter icon" /></a>*/}
                  {/* </div> */}
                </div>
                <img src={require('../../assets/img/usce-building.png')} alt="usce" className="usce-img" />

              </div>
            </div>
            <div className="item">
              <p> Thank you, your message was sent successfully. </p>
            </div>
          </div>
        </Grid>
        {/* <img src={require('../../assets/img/office-view.png')} alt="office view" /> */}
        <div className="bg-image" />
      </Grid>
    )
  }
}
