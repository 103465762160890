import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    height: 40,
    fontSize: 14,
    padding: '10px 25px',
    color: '#10336B',
    background: '#67E3D0',
    borderRadius: 10,
    "&:hover": {
      backgroundColor: 'rgba(103, 227, 208, 0.7)'
    }
  },
});

class AppButton extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <Button className={classes.root} {...this.props} >
        <p className="button-text">{this.props.title}</p>
      </Button>
    );
  }
}

AppButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppButton);