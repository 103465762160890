import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
export default class OurServices extends Component {
state = {
firstActive: true,
secondActive: false,
thirdActive: false,
}
render() {
return (
<Grid item xs={12} className="our-services">
  <Element name="services" className="element" >
  <div className="inner">
    <div>
      <h2 className="section-title" ><span>what can we do for you</span> our services</h2>
    </div>
    <div class="content">
      <div className="services">
        <div onClick={() => this.setState({ firstActive: true, secondActive: false, thirdActive: false })} className={this.state.firstActive ? "active" : "inactive" }>
          <div className="image-wrapper">
            <img src={require('../../assets/img/blockchain-illustration.png')} alt="blockchain" />
          </div>
          <h3>BLOCKCHAIN & AI<br />DEVELOPMENT</h3>
          {this.state.firstActive ?
          <p className="text">
            Our company understands how blockchain and DLT create business value and how
            essential it is to understand the right use cases to be able to move beyond
            small pilot projects and enter the area of widespread adoption.<br /> <br /> In year 2020
            many goal posts have been moved to better suit the needs of businesses and end users.
            By combining different technologies in this space we have managed to create a genuine
            hybrid system of decentralized ledger and deep learning experience to maximize the
            possibility of harvesting “good data” in a flexible but swift manner. Our projects
            are utilizing this system mainly to provide utility to other businesses where personalization
            of information is of crucial importance. While maintaining the security aspect at the highest
            levels, we harvest depersonalized data and shape it in forms which can be processed to provide
            completely personalized service.<br /> <br /> <br /> Want to learn more about our projects?
            &nbsp; <Link to="/contact" style={{ color: '#A31F36' }}>Contact us</Link>
          </p>
          : null}
        </div>
        <div onClick={() => this.setState({ firstActive: false, secondActive: true, thirdActive: false })} className={this.state.secondActive ? "active" : "inactive" }>
          <div className="image-wrapper">
            <img src={require('../../assets/img/mobile-web-illustration.png')} alt="development" />
          </div>
          <h3>MOBILE & WEB<br />DEVELOPMENT</h3>
          {this.state.secondActive ?
          <p className="text">
            Through use of native mobile technologies we can achieve virtually anything. Depending from
            the features which we offer, we always look to tailor the solutions to your needs. For us it
            is important to have the same experience as much as possible whether you are using web or
            mobile version of our product. That is why we have teams dedicated to developing iOS and
            Android solutions, so anyone can use our platforms on any device.
          </p>
          : null}
        </div>
        <div onClick={() => this.setState({ firstActive: false, secondActive: false, thirdActive: true })} className={this.state.thirdActive ? "active" : "inactive" }>
          <div className="image-wrapper">
            <img src={require('../../assets/img/consulting.png')} alt="consulting" />
          </div>
          <h3>CONSULTING <br /> </h3>
          {this.state.thirdActive ?
          <p className="text">
            You only have an idea but you are not sure how to start the project? Not sure which technologies
            to use? Not sure how many people you will need or professional profile? We are here to help you
            with all questions that you might have regarding industry analysis and development!
          </p>
          : null}
        </div>
      </div>
    </div>
  </div>
  </Element>
</Grid>
)
}
}