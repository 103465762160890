import React, { Component } from 'react'
import OurServices from './OurServices';
import Technologies from './Technologies';
import Projects from './Projects';
import FirstSection from './FirstSection';
import { Link } from 'react-scroll';
import Grid from '@material-ui/core/Grid';

export default class Home extends Component {
  render() {
    return (
      <Grid container >
         <div className="side-navigation">
          <ul>
            <li>
              <Link activeClass="active" className="scroll-link first" to="home" spy={true} smooth={true} offset={0} duration={500} >
                  <p>home</p>
              </Link>
            </li>
            <li>
              <Link activeClass="active" className="scroll-link" to="about" spy={true} smooth={true} offset={0} duration={500} >
                  <p>about</p>
              </Link>
            </li>
            <li>
              <Link activeClass="active" className="scroll-link" to="services" spy={true} smooth={true} offset={0} duration={500} >
                  <p>services</p>
              </Link>
            </li>
            <li>
              <Link activeClass="active" className="scroll-link" to="technologies" spy={true} smooth={true} offset={0} duration={500} >
                  <p>technologies</p>
              </Link>
            </li>
            <li>
              <Link activeClass="active" className="scroll-link" to="projects" spy={true} smooth={true} offset={0} duration={500} >
                  <p>projects</p>
              </Link>
            </li>
          </ul>
        </div>
        <FirstSection />
        <OurServices />
        <Technologies />
        <Projects />
      </Grid>
    )
  }
}
