import React, { Component } from 'react'
import AppButton from '../../elements/form/AppButton';

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        {/* <div className="top-border"/> */}
        <div className="container">
          <div className="item">
            <div>
              <h2>COMPANY</h2>
              <a href="/about">About</a>
              <a href="/contact">Contact</a>
            </div>
          </div>
          <div className="item">
            <h2>FOLLOW US</h2>
            <div className="icons-wrapper">
              <a href="https://www.facebook.com/its42company/" target="blank" rel="noopener noreferrer"><img src={require('../../assets/img/facebook-letter-logo.svg')} className="social-icon"  alt="facebook icon"  /></a>
              <a href="https://twitter.com/ITS42company" target="blank" rel="noopener noreferrer"><img src={require('../../assets/img/twitter-letter-logo.svg')}  className="social-icon" alt="twitter icon"  /></a>
              <a href="https://www.linkedin.com/company/its42" target="blank" rel="noopener noreferrer">  <img src={require('../../assets/img/linkedin-logo.svg')} className="social-icon"  alt="facebook icon"  /></a>
            </div>
          </div>
          <div className="item">
            <h2 className="newsletter-title"> Email Subscription</h2>
            <div className="newsletter-wrapper">
              <input placeholder="Your email address" type="email" className="newsletter-input" style={{ border: '1px solid gray', borderRadius: 10 }} />
              {/* <button >Sign up</button> */}
              <AppButton title="Sign up" style={{minWidth: 120, borderRadius: 10, height: 43, background: '#98182f'}}/>
            </div>
          </div>
        </div>
        <p style={{ textAlign: 'center' }}>Copyright @ 2020 ITS42 doo. All rights reserved.</p>
      </div>
    )
  }
}
