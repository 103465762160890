import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Element } from 'react-scroll';
import AppButton from '../../elements/form/AppButton';
import { Link } from 'react-router-dom';

export default class FirstSection extends Component {

  _animateLogo() {
    if(window.innerWidth < 991) {
      return
    }
    let logo = document.getElementById('logo');
    logo.className = "logo resize";
    setTimeout(() => {
      logo.className = "logo resize move-to-top";
    }, 1000);
  }

  _animateText() {
    if(window.innerWidth < 991) {
      return
    }
    let text1 = document.getElementById('span1');
    let text2 = document.getElementById('span2');
    let text3 = document.getElementById('span3');
    let title = document.getElementById('title')
    text1.className = 'title-span hide-text'
    text2.className = 'title-span hide-text'
    text3.className = 'title-span hide-text'
    setTimeout(() => {
      title.className = 'move-text'
    }, 1000);
  }

  render() {
    setTimeout(() => {
      this._animateLogo()
      this._animateText()
    }, 2200);
    return (
      <>
        <Element name="home" className="element" style={{ width: '100%' }}>
          <Grid item xs={12} className="first-section">
            <video id="background-video" autoPlay loop muted playsInline >
              <source src={require('../../assets/video/background-cubes-1.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
             </video>
            <div className="arrow-bottom" />
            {/* <div className="wrapper"> */}
              <img src={require('../../assets/img/logo-new.svg')} onClick={() => window.location.replace('/')} id="logo" alt="logo" className="logo" />
              <h1 id="title">I<span id="span1" className="title-span">NTELLIGENT</span> T<span id="span2" className="title-span">ECHNOLOGY</span> S<span id="span3" className="title-span">OLUTIONS</span></h1>
            {/* </div> */}
          </Grid>
        </Element>
        <Element name="about" className="element">
          <Grid item xs={12} className="second-section">
            <div className="img">
            </div>

            <div className="inner">
              <div>
                <h2 className="section-title" ><span>get to know us</span> our story</h2>
                <p>We are a software development company that is dedicated to creating innovative
                  solutions for business needs.
            <br />
                  <br />
                  Through the years we have gathered experience in
                 different industries, which we now use to deliver products that have a real market
                 value. From conceptual idea to a full product, our team strives to make best
                 user-experience for our customers.
            <br />
                  <br />
                  Even though we develop our own product portfolio,
            we also have dedicated teams that can help other businesses bring their ides to life.</p>
              </div>
              {/* <div className="arrow-left" /> */}
            <Link to="/about#team"><AppButton title="Meet the team" className="btn red" /></Link>
            </div>
          </Grid>
        </Element>
      </>
    )
  }
}
