import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from "react-router-dom";
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  list: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  fullList: {
    width: 'auto',
  },
  toolbar: {
    position: 'absolute',
    zIndex: 10000
  },
  drawer: {
    width: '100%',
    background: '#000',
    overflow: 'hidden'
  },
  menuButton: {
    position: 'fixed',
    right: 5,
    color: 'black',
    zIndex: 1000
  },
  colorWhite: { color: 'white', minWidth: 50 },
  colorViolet: { color: '#5445BC' },
  link: { textDecoration: 'none', fontFamily: 'Montserrat-Medium' },
  listItem: {
    textAlign: 'center',
    marginBottom: 10,
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Montserrat-Medium',
      ].join(','),
      fontSize: 14,
    },
  },
  hide: {
    display: 'none',
  },
  linkText: {
    color: 'white',
    fontSize: 13,
    textAlign: 'center'
  },
});

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >

      <List>
        <Link to={'/'} className={classes.link}>
          <ListItem button className={classes.listItem}>
            <ListItemText
              className={clsx(classes.linkText, {
                // [classes.hide]: !open,
              })}
              primary={'Home'} />
          </ListItem>
        </Link>
        <Link to={'/about'} className={classes.link}>
          <ListItem button className={classes.listItem}>
            <ListItemText
              className={clsx(classes.linkText, {
                // [classes.hide]: !open,
              })}
              primary={'About'} />
          </ListItem>
        </Link>
        <Link to={'/projects'} className={classes.link}>
          <ListItem button className={classes.listItem}>
            <ListItemText
              className={clsx(classes.linkText, {
                // [classes.hide]: !open,
              })}
              primary={'Projects'} />
          </ListItem>
        </Link>
        <Link to={'/careers'} className={classes.link}>
          <ListItem button className={classes.listItem}>
            <ListItemText
              className={clsx(classes.linkText, {
                // [classes.hide]: !open,
              })}
              primary={'Careers'} />
          </ListItem>
        </Link>
        <Link to={'/blog'} className={classes.link}>
          <ListItem button className={classes.listItem}>
            <ListItemText
              className={clsx(classes.linkText, {
                // [classes.hide]: !open,
              })}
              primary={'Blog'} />
          </ListItem>
        </Link>
        <Link to={'/contact'} className={classes.link}>
          <ListItem button className={classes.listItem}>
            <ListItemText
              className={clsx(classes.linkText, {
                // [classes.hide]: !open,
              })}
              primary={'Contact'} />
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
      <div className={classes.toolbar}>
        <IconButton
          onClick={toggleDrawer('left', false)}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: !state.left,
          })}>
          <ClearIcon style={{fontSize: 28, color: 'white'}}/>

        </IconButton>
        <IconButton
          aria-label="Open drawer"
          onClick={toggleDrawer('left', true)}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: state.left,
          })}
        >
          <MenuIcon style={{fontSize: 28}}/>
        </IconButton>
      </div>
      {/* <IconButton
        aria-label="Open drawer"
        onClick={toggleDrawer('left', true)}
        edge="end"
        className={clsx(classes.menuButton, {
        })}
      >
        <MenuIcon />
      </IconButton> */}
      <SwipeableDrawer
        anchor="right"
        open={state.left}
        // className={clsx(classes.drawer)}
        classes={{
          paper: clsx(
            classes.drawer
          ),
        }}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {sideList('left')}
      </SwipeableDrawer>
    </div>
  );
}