import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import { Element } from 'react-scroll';

export default class OurServices extends Component {


  render() {
    return (
      <Grid item xs={12} className="technologies">
        <Element name="technologies" className="element" >
        <div className="inner">
          <div style={{ textAlign: 'right' }}>
            <h2 className="section-title"><span>we master the following</span> technologies</h2>
          </div>
          <div className="logos-wrapper">
            <div><img src={require('../../assets/img/tech-logos/nodejs.png')} alt="nodejs" /></div>
            <div><img src={require('../../assets/img/tech-logos/angularjs.png')} alt="angular" /></div>
            <div><img src={require('../../assets/img/tech-logos/php.png')} alt="php" /></div>
            <div><img src={require('../../assets/img/tech-logos/python.png')} alt="python" /></div>
            <div><img src={require('../../assets/img/tech-logos/htmlcssjs.png')} alt="html" /></div>
            <div><img src={require('../../assets/img/tech-logos/ruby.png')} alt="ruby" /></div>
            <div><img src={require('../../assets/img/tech-logos/java.png')} alt="java" /></div>
            <div><img src={require('../../assets/img/tech-logos/dotnet1.png')} alt="dotnet" /></div>
            <div><img src={require('../../assets/img/tech-logos/react.png')} alt="react" /></div>
            <div><img src={require('../../assets/img/tech-logos/swift.png')} alt="swift" /></div>
            <div><img src={require('../../assets/img/tech-logos/android.png')} alt="android" /></div>
            <div><img src={require('../../assets/img/tech-logos/ios.png')} alt="ios" /></div>
          </div>

        </div>
        <div className="arrow-bottom" />
        </Element>
      </Grid>
    )
  }
}
