import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class NavLogo extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location) {
            this._showLogo();
        }
    }
    componentDidMount() {
        this._showLogo()
    }

    _showLogo() {
        let logo = document.getElementById('navLogo');
        if (this.props.location.pathname !== '/') {
            logo.className = 'nav-logo-wrapper'
        } else {
            logo.className = 'nav-logo-wrapper hide'
        }
    }

    render() {
        return (
            <Link to="/">
                <div id="navLogo" className="nav-logo-wrapper">
                    {/* <p>ITS</p> */}
                    <p><span>I</span><span>T</span><span>S</span></p>
                    <img src={require('../../assets/img/logo-new.svg')} alt="logo" className="nav-logo" />
                </div>
            </Link>
        )
    }
}
